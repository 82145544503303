import React from "react";
import "./navbar.scss";
import { Route, Link } from "react-router-dom";

import logo from "assets/images/img-logo2.png";
import Team from "pages/Team/Team";

import { SERVICE_LINK, COMPANY_LINK, HIRING_LINK } from "services/Links";

function Navbar() {
  return (
    <div className="Navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <p>Apeiro</p>
        </div>
        <div className="links">
          <div className="link-1 ">
            <a className="no-text-decoration" href={COMPANY_LINK}>
              About us{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
