import React from "react";
import { SERVICE_LINK, COMPANY_LINK, HIRING_LINK } from "services/Links";

//Components and Css
import "./home.scss";

function Home() {
  return (
    <div className="homepage">
      <div className="main-container">
        <div className="bg-image"></div>
        <p id="subtitle">WELCOME TO APEIRO </p>
        <p id="title">
          We are a group of language enthusiasts, building the fastest way to
          learn a new language.
        </p>

        <div className="service-link-container">
          <div className="link">
            <form action={SERVICE_LINK}>
              <input
                className="service-button"
                type="submit"
                value="OUR PRODUCT"
              />
            </form>
          </div>
          <div className="link">
            <form action={HIRING_LINK}>
              <input
                className="hiring-button"
                type="submit"
                value="WE ARE HIRING !!"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
