import React from "react";

import img_mh from "assets/images/profile_mh.png";
import img_yc from "assets/images/profile_yc.png";
import img_hd from "assets/images/profile_hd.png";
import img_ys from "assets/images/profile_ys.png";

import "./team.scss";

export default function Team() {
  return (
    <section className="Team">
      <div className="main-container">
        <div className="title">
          <h1>MEET THE TEAM</h1>
        </div>

        <div className="divider">
          <hr className="teamdivider"></hr>
        </div>
        <div className="team-grid-container">
          <div className="profile-1">
            <div className="profile-image">
              <img src={img_mh} id="profile-img1" alt="profile-img1"></img>
            </div>
            <div className="profile-title">
              <span className="text-highlight">CEO</span>, Co-Founder,
            </div>
            <div className="profile-name">
              <h3>MINHYUNG JOO</h3>
            </div>
            <div className="profile-background">
              <h4>Bachelors in CS, HKUST</h4>
              <h4>Former Engineer at CloverHealth</h4>
            </div>
          </div>

          <div className="profile-2">
            <div class="profile-image">
              <img src={img_yc} id="profile-img2" alt="profile-img2"></img>
            </div>
            <div class="profile-title">
              <span className="text-highlight">COO</span>, Co-Founder
            </div>
            <div className="profile-name">
              <h3>MINHYUNG JOO</h3>
            </div>
            <div className="profile-background">
              <h4>Bachelors in Biz, Sogang</h4>
              <h4>Former Engineer at LG Intl</h4>
            </div>
          </div>

          <div className="profile-3">
            <div class="profile-image">
              <img src={img_hd} id="profile-img3" alt="profile-img3"></img>
            </div>
            <div class="profile-title">
              <span className="text-highlight">CRO</span>, Co-Founder
            </div>
            <div className="profile-name">
              <h3>JUSTIN CHO</h3>
            </div>
            <div className="profile-background">
              <h4>Bachelors in CS, HKUST</h4>
              <h4>Former NLP Engineer at Meta</h4>
            </div>
          </div>

          <div className="profile-4">
            <div class="profile-image">
              <img src={img_ys} id="profile-img4" alt="profile-img4"></img>
            </div>
            <div class="profile-title">
              <span className="text-highlight">CTO</span>, Co-Founder
            </div>
            <div className="profile-name">
              <h3>CHARLIE YOON</h3>
            </div>
            <div className="profile-background">
              <h4>Bachelors in EE, HKUST</h4>
              <h4>Former Engineer at Data AI</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
